import {
  ASSESSMENT_AVERAGE_TIME_STATS,
  ASSESSMENT_ERROR,
  ASSESSMENT_QUESTIONNAIRES,
  ASSESSMENT_QUESTIONS,
  ASSESSMENT_RECORD,
  CAREER_PATH,
  CHECK_SURVEY_ALREADY_SUBMITTED,
  CHECK_TENANT_EXISTENCE,
  GET_FAV_JOBS,
  GET_JOB_MATCH_AGAINST_JOB_ID,
  GET_MATCHING_JOBS,
  INIT_CANDIDATE,
  REGISTER_TENANT,
  SEARCH_ANY_JOB,
  SET_JOBS_LOADING,
  SUBMIT_APP_ASSESSMENT_ANSWERS,
  SUBMIT_APP_ASSESSMENT_ANSWER_MEDIA,
  SUBMIT_APP_ASSESSMENT_MEDIA,
  SUBMIT_ASSESSMENT_ANSWERS,
  TENANT_INFO_DETAILS,
  TENANT_INFO_LOADING,
  TOGGLE_JOB_FAV_STATUS,
  TOGGLE_JOB_FAV_STATUS_LOADING,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/candidates/constants";
import { Commit, Dispatch } from "vuex";
import {
  assessment_average_times,
  check_survey_already_submitted,
  check_tenant_existence,
  fetch_assessment_questionnaries_by_id,
  fetch_assessment_questions,
  fetch_career_path,
  fetch_fav_jobs,
  get_matching_jobs,
  register_tenant,
  search_whole_system_jobs,
  skill_gap_against_job,
  submit_app_assessment_media,
  submit_assessment_answer_media,
  submit_assessment_answers,
  submit_assessment_answers_by_id,
  toggle_job_fav_status,
  upskill_courses
} from "@/store/apis/candidate";
import {
  AssessmentRecord,
  CareerPathPayload,
  RegisterTenantPayload,
  SearchAnyJobPayload,
  SubmitAssessmentAnswersPayload,
  ToggleJobFavStatusPayload,
  UpskillCoursesActionPayload,
  GetMatchingJobsPayload,
  FetchMatchingJobResponse,
  FetchAssessmentQuestionnariesPayload,
  SubmitAssessmentAnswersApiPayload,
  SubmitAppAssessmentMediaPayload,
  AssessmentAverageTimePayload
} from "@/store/modules/candidates/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import router from "@/router";
import { USER_PROFILE } from "@/store/modules/auth/constants";
import { GetJobMatchActionPayload } from "@/store/modules/common/interfaces";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { default_career_path } from "@/interfaces/responses/career_path";
import { AssessmentQuestions } from "@/interfaces/responses/assessment_questions";
import i18n from "@/i18n";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
export default {
  [GET_MATCHING_JOBS]: async (
    { commit }: { commit: Commit },
    payload: GetMatchingJobsPayload
  ): Promise<FetchMatchingJobResponse | null> => {
    try {
      const response = await get_matching_jobs(payload);
      return response.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  /**
   * Get upskill courses
   */
  [UPSKILL_COURSES]: async (
    { commit }: { commit: Commit },
    payload: UpskillCoursesActionPayload
  ): Promise<void> => {
    try {
      const response = await upskill_courses(payload);
      const upskill_nf = response.data.data.upskill_nf; // Extract upskill new format
      const providers_scores: { [key: string]: number } = {};
      // Calculate all courses sum of all providers
      for (const provider in upskill_nf) {
        const courses_list = upskill_nf[provider];
        providers_scores[provider] = courses_list.reduce(
          (sum: number, course: UpskillCourses.UpskillCourseDetail) =>
            sum + course.score,
          0
        );
      }
      // Sort providers based on the calculated sum
      const sorted_providers = Object.entries(providers_scores).sort(
        (a, b) => b[1] - a[1]
      );
      // Create new sorted providers data
      const upskill_data: UpskillCourses.UpskillNewFormat = {};
      for (const [provider] of sorted_providers) {
        upskill_data[provider] = upskill_nf[provider];
      }
      response.data.data.upskill_nf = upskill_data; // Rewrite upskill_nf default output
      commit(UPSKILL_COURSES, response.data.data);
      // commit(UPSKILL_COURSES, json_d);
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(UPSKILL_COURSES_LOADING, false);
      await router.push({
        name: "candidate-error"
      });
    }
  },
  /**
   * Initialize candidate data
   */
  [INIT_CANDIDATE]: async ({
    dispatch
  }: {
    dispatch: Dispatch;
  }): Promise<boolean> => {
    try {
      // await dispatch(`auth/${USER_PROFILE}`, {}, { root: true });
      return true;
    } catch (e) {
      return false;
    }
  },
  /**
   * Action to get job match by job id
   * @param job_id:number
   */
  [GET_JOB_MATCH_AGAINST_JOB_ID]: async (
    { commit }: { commit: Commit },
    payload: GetJobMatchActionPayload
  ): Promise<Candidates.Candidates | boolean> => {
    try {
      // API call
      const response = await skill_gap_against_job(payload);
      const data = response.data.data;
      // If data received and have matched job details
      if (data.length > 0 && data[0].cvs.length > 0) {
        return data[0].cvs[0];
      } else return false;
    } catch (e) {
      return false;
    }
  },
  [SEARCH_ANY_JOB]: async (
    { commit }: { commit: Commit },
    payload: SearchAnyJobPayload
  ): Promise<Jobs[]> => {
    try {
      commit(SET_JOBS_LOADING, true);
      const response = await search_whole_system_jobs(payload);
      commit(SET_JOBS_LOADING, false);
      return response.data.data.results;
    } catch (e) {
      commit(SET_JOBS_LOADING, false);
      return [];
    }
  },
  /**
   * Fetching career path
   * @param commit
   * @param {string} upload_response_id: Career path to fetch against upload_response_id
   */
  [CAREER_PATH]: async (
    { commit }: { commit: Commit },
    upload_response_id: string
  ): Promise<any> => {
    try {
      const payload: CareerPathPayload = {
        uid: upload_response_id,
        n: 3
      };
      const response = await fetch_career_path(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  /**
   * Action to toggle job fav status
   * @param commit
   * @param {ToggleJobFavStatusPayload} payload
   */
  [TOGGLE_JOB_FAV_STATUS]: async (
    { commit }: { commit: Commit },
    payload: ToggleJobFavStatusPayload
  ): Promise<void | null> => {
    try {
      commit(TOGGLE_JOB_FAV_STATUS_LOADING, true);
      const response = await toggle_job_fav_status(payload);
      commit(TOGGLE_JOB_FAV_STATUS_LOADING, false);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(TOGGLE_JOB_FAV_STATUS_LOADING, false);
      return null;
    }
  },
  /**
   * Action to fetch fav jobs against logged in user
   * @param commit
   * @param job_ids
   */
  [GET_FAV_JOBS]: async (
    { commit }: { commit: Commit },
    job_ids: number[]
  ): Promise<void | null> => {
    try {
      const response = await fetch_fav_jobs(job_ids);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, "Failed to get favorite jobs status", { root: true });
    }
  },

  [REGISTER_TENANT]: async (
    { commit }: { commit: Commit },
    payload: RegisterTenantPayload
  ): Promise<boolean> => {
    try {
      commit(TENANT_INFO_LOADING, true);
      await register_tenant(payload);
      const response = await check_tenant_existence();
      commit(TENANT_INFO_DETAILS, response.data.data);
      commit(TENANT_INFO_LOADING, false);
      commit(ASSESSMENT_ERROR, false);
      return true;
    } catch (e) {
      commit(TENANT_INFO_LOADING, false);
      return false;
    }
  },
  [CHECK_TENANT_EXISTENCE]: async ({
    commit
  }: {
    commit: Commit;
  }): Promise<boolean> => {
    try {
      commit(TENANT_INFO_LOADING, true);
      const response = await check_tenant_existence();
      commit(TENANT_INFO_DETAILS, response.data.data);
      commit(TENANT_INFO_LOADING, false);
      commit(ASSESSMENT_ERROR, false);
      return true;
    } catch (e) {
      commit(TENANT_INFO_LOADING, false);
      return false;
    }
  },
  [ASSESSMENT_QUESTIONS]: async ({
    commit
  }: {
    commit: Commit;
  }): Promise<void> => {
    try {
      commit(TENANT_INFO_LOADING, true);
      const response = await fetch_assessment_questions();
      commit(ASSESSMENT_QUESTIONS, response.data.data);
      const data: AssessmentRecord[] = response.data.data.questions.map(
        (val: AssessmentQuestions.Questions) => {
          const obj: AssessmentRecord = {
            answerNumber: -1,
            question: val.text,
            questionNumber: val.number
          };
          return obj;
        }
      );
      commit(ASSESSMENT_RECORD, data);
      commit(TENANT_INFO_LOADING, false);
      commit(ASSESSMENT_ERROR, false);
    } catch (e) {
      commit(TENANT_INFO_LOADING, false);
      commit(ASSESSMENT_ERROR, true);
      commit(ROOT_ERROR, i18n.t("assessments.failed-error"), { root: true });
    }
  },
  [SUBMIT_ASSESSMENT_ANSWERS]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: SubmitAssessmentAnswersPayload
  ): Promise<void> => {
    try {
      commit(TENANT_INFO_LOADING, true);
      await submit_assessment_answers(payload);
      commit(TENANT_INFO_LOADING, false);
    } catch (e) {
      commit(TENANT_INFO_LOADING, false);
      commit(ASSESSMENT_ERROR, true);
    }
  },
  [ASSESSMENT_QUESTIONNAIRES]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: FetchAssessmentQuestionnariesPayload
  ): Promise<null | AppAssessments.AssessmentQuestionnaires[]> => {
    try {
      const response = await fetch_assessment_questionnaries_by_id(
        payload.assessment_id
      );
      const data = response.data.data;
      if (data.length > 0) return response.data.data;
      else return null;
    } catch (e) {
      return null;
    }
  },
  [SUBMIT_APP_ASSESSMENT_ANSWERS]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: SubmitAssessmentAnswersApiPayload
  ): Promise<AppAssessments.AssessmentAnswers | null> => {
    try {
      const resposne = await submit_assessment_answers_by_id(payload);
      return resposne.data.data;
    } catch (e) {
      return null;
    }
  },
  [SUBMIT_APP_ASSESSMENT_MEDIA]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: SubmitAppAssessmentMediaPayload
  ): Promise<AppAssessments.AssessmentAnswers | null> => {
    try {
      const resposne = await submit_app_assessment_media(payload);
      return resposne.data.data;
    } catch (e) {
      return null;
    }
  },
  [SUBMIT_APP_ASSESSMENT_ANSWER_MEDIA]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: FormData
  ): Promise<AppAssessments.AssessmentAnswers | null> => {
    try {
      const resposne = await submit_assessment_answer_media(payload);
      return resposne.data.data;
    } catch (e) {
      return null;
    }
  },
  [CHECK_SURVEY_ALREADY_SUBMITTED]: async ({
    commit
  }: {
    commit: Commit;
  }): Promise<boolean> => {
    try {
      await check_survey_already_submitted();
      return true;
    } catch (e) {
      return false;
    }
  },
  [ASSESSMENT_AVERAGE_TIME_STATS]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: AssessmentAverageTimePayload
  ): Promise<boolean> => {
    try {
      await assessment_average_times(payload);
      return true;
    } catch (e) {
      return false;
    }
  }
};
