import {
  AL_AIN_USER_FILTER,
  CRITERIA_FILTER,
  GET_ALL_CANDIDATES,
  VIEW_CANDIDATE_DATA,
  VIEW_CANDIDATE_FILTER
} from "@/store/modules/recruiter_admin/constants";
import {
  AllCandidates,
  RecruiterAdmin
} from "@/store/modules/recruiter_admin/interfaces";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import { SearchedCandidate } from "../recruiter/interfaces";

export default {
  [GET_ALL_CANDIDATES]: (state: RecruiterAdmin): AllCandidates =>
    state.candidates,
  [VIEW_CANDIDATE_DATA]: (state: RecruiterAdmin): SearchedCandidate | null =>
    state.view_candidate_data,
  [VIEW_CANDIDATE_FILTER]: (state: RecruiterAdmin): ViewCandidatesOptions =>
    state.view_candidate_filter,
  [AL_AIN_USER_FILTER]: (state: RecruiterAdmin): boolean => state.al_ain_users,
  [CRITERIA_FILTER]: (state: RecruiterAdmin): string[] => state.criteria_filter
};
