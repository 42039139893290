import getters from "@/store/modules/recruiter/getters";
import mutations from "@/store/modules/recruiter/mutations";
import actions from "@/store/modules/recruiter/actions";
import {
  AssessmentVersions,
  RecruiterState
} from "@/store/modules/recruiter/interfaces";

export default {
  namespaced: true,
  state: (): RecruiterState => ({
    loading: false,
    post_job_limit_error: "",
    jobs_posted_loading: false,
    poke_candidate_loading: false,
    add_standardised_job: null,
    edited_Standardised_job: false,
    view_candidates_dynamically: {
      active: false,
      loading: false,
      job_id: null,
      job_title: "",
      skill: "",
      user_ids: []
    },
    // Using in job listing page
    jobs_posted: {
      pagination: 1,
      total: 0,
      results: [],
      date_filter: [],
      searched_job_title: null
    },
    // Using to display jobs in dashboard
    top_jobs_posted: {
      total: 0,
      results: []
    },
    recommend_job_profiles: [],
    email_templates: [],
    survey_questions: [],
    // When recruiter want to view matching candidate this payload will contain job_id & cv_id
    // Job id and cv id is required to view candidate profile
    candidate_view_payload: null,
    all_candidates: {
      total: 0,
      results: [],
      filtered_count: -1
    },
    interviews: {
      search_job_loading: false,
      searched_job: null,
      pagination: 1,
      interview_listing: new Map(),
      selected_candidate: []
    },
    view_candidates_pagination: {
      pagination: 1
    },
    assessment_listing: {
      fetch_loading: false,
      selected_dates: [],
      pagination: 1,
      data: {
        total: 0,
        results: []
      }
    },
    eng_assessment_listing: {
      limit: 10,
      page: 1,
      total: 0,
      results: [],
      selected_dates: {
        date_picker_menu: false,
        date_range_value: "",
        date_filter: []
      },
      selected_candidates: [],
      score_filter: "both",
      status_filter: "all"
    },
    annotation_assessment_listing: {
      limit: 10,
      page: 1,
      total: 0,
      results: [],
      selected_dates: {
        date_picker_menu: false,
        date_range_value: "",
        date_filter: []
      },
      selected_candidates: [],
      score_filter: "both",
      status_filter: "all"
    },
    eng_assessment_listing_v2: {
      limit: 10,
      page: 1,
      total: 0,
      results: [],
      selected_dates: {
        date_picker_menu: false,
        date_range_value: "",
        date_filter: []
      },
      selected_candidates: []
    },
    annotation_assessment_listing_v2: {
      limit: 10,
      page: 1,
      total: 0,
      results: [],
      selected_dates: {
        date_picker_menu: false,
        date_range_value: "",
        date_filter: []
      },
      selected_candidates: []
    },
    active_assessment: {
      assessment_id: null,
      assessment_type: ""
    },
    inseption_stats: {
      users: "0",
      englishAssessment: "0",
      annotationAssessment: "0",
      allUsers: "0"
    },
    overall_assessment_listing: {
      page: 1,
      limit: 10,
      total: 0,
      selected_dates: {
        date_picker_menu: false,
        date_range_value: "",
        date_filter: []
      },
      results: [],
      selected_candidates: [],
      score_filter: "both",
      average_score_filter: [0, 100],
      english_assessment_filter: [0, 100],
      annotation_assessment_filter: [0, 100],
      assessment_versions: [AssessmentVersions.VERSION_3],
      eid_name_filter: "Name"
    },
    cancelled_assessment_candidates: {
      page: 1,
      limit: 10,
      total: 0,
      results: [],
      user_ids: [],
      assessment_version: "english_v1"
    },
    dashboard_counts: {
      retaken_assessment_count: 0,
      more_than_twice_retaken: 0,
      signup_to_assessment_time: "00:00",
      new_candidate_count: 0,
      completed_all_assessments: 0,
      eng_assessment_avg_time: "00:00",
      anno_assessment_avg_time: "00:00",
      eng_speaking_avg_time: "00:00",
      eng_mcq_avg_time: "00:00",
      eng_writing_avg_time: "00:00",
      anno_part1_avg_time: "00:00",
      anno_part2_avg_time: "00:00",
      anno_part3_avg_time: "00:00",
      anno_part4_avg_time: "00:00",
      anno_part5_avg_time: "00:00",
      anno_part6_avg_time: "00:00",
      anno_part7_avg_time: "00:00",
      anno_part8_avg_time: "00:00",
      admin_approved_candidates: 0,
      auto_approved_candidates: 0,
      eng_assessment_candidates: 0,
      anno_assessment_candidates: 0,
      cancelled_assessments: 0,
      passed_assessments: 0,
      failed_assessments: 0,
      avg_time_ans: "00:00",
      registered_users: 0
    },
    redo_assessment_candidates: {
      page: 1,
      limit: 10,
      total: 0,
      results: [],
      user_ids: [],
      assessment_version: "english_v1"
    }
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
