import { Roles } from "@/interfaces/data_objects/roles";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { NotificationsResponse } from "@/interfaces/responses/notifications";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { IApp } from "@/interfaces";
/**
 * Common state main interface
 */
export interface CommonState {
  loading: boolean;
  sectors: SectorData[];
  roles: Roles[];
  users_info: SearchUserObject[];
  selected_users: SearchUserObject[];
  user_info_by_eid_name: SearchUserObject[];
  selected_users_by_eid_name: SearchUserObject[];
  searched_job: Jobs | null;
  view_job_state: ViewJobState;
  notifications: Notifications;
  site_direction: SiteDirections;
  app_assessments: AppAssessments.Assessment[];
  upload_file_chunk_loading: boolean;
}

export interface SectorData {
  sector: string;
}

export interface FetchSkillsGroupActionPayload {
  skills: string[];
}

export enum ViewJobState {
  DEFAULT = "DEFAULT",
  JOB_LISTING = "JOB_LISTING",
  STANDARDISED = "STANDARDISED",
  STANDARDISED_JOB_POST = "STANDARDISED_JOB_POST",
  LINKEDIN_JOB_POST = "LINKEDIN_JOB_POST",
  FILE_JOB_POST = "FILE_JOB_POST",
  TEXT_JOB_POST = "TEXT_JOB_POST",
  EDIT_TEXT_JOB_POST = "EDIT_TEXT_JOB_POST"
}

export interface GetJobMatchActionPayload {
  cv_id: string;
  job_id: number;
}

export interface SkillSearchPayload {
  page: number;
  limit_per_page: number;
  skill_name: string;
}

export interface FetchNotificationListPayload {
  page: number;
  limit: number;
  is_read?: boolean;
}

export interface UpdateNotificationStatusPayload {
  notificationIds: number[];
}

export interface Notifications {
  loading: boolean;
  count: number;
  notifications: NotificationsResponse[];
  action_loading: boolean;
}

export enum SiteDirections {
  RTL = "rtl",
  LTR = "ltr"
}

export enum LanguageKey {
  ENGLISH = "en",
  ARABIC = "ar"
}

export enum AppAssessmentNames {
  english_v1 = "english_v1",
  english_v2 = "english_v2",
  english_v3 = "english_v3",
  annotation_v1 = "annotation_v1",
  annotation_v2 = "annotation_v2",
  annotation_v3 = "annotation_v3"
}

export interface AppAssessmentResult {
  assessment_id: number;
  user_id?: number;
}

export interface SendEmailPayload {
  user_ids: number[];
  type: IApp.EmailInvites;
}

export interface UploadFileChunkPayload {
  file: File;
  filename: string;
  start: number;
}

export enum DashboardStats {
  retaken_assessment_count = "Retaken Assessment Count",
  more_than_twice_retaken = "More Than Twice Retaken",
  signup_to_assessment_time = "Signup to Assessment Time",
  new_candidate_count = "New Candidate Count",
  completed_all_assessments = "Completed All Assessments",
  eng_assessment_avg_time = "English Assessment Average Time",
  anno_assessment_avg_time = "Annotation Assessment Average Time",
  eng_speaking_avg_time = "English Speaking Average Time",
  eng_mcq_avg_time = "English MCQ Average Time",
  eng_writing_avg_time = "English Writing Average Time",
  anno_speaking_avg_time = "Annotation Speaking Average Time",
  anno_mcq_avg_time = "Annotation MCQ Average Time",
  anno_writing_avg_time = "Annotation Writing Average Time",
  admin_approved_candidates = "Admin Approved Candidates",
  auto_approved_candidates = "Auto Approved Candidates",
  eng_assessment_candidates = "English Assessment Candidates",
  anno_assessment_candidates = "Annotation Assessment Candidates",
  cancelled_assessments = "Cancelled Assessments",
  passed_assessments = "Passed Assessments",
  failed_assessments = "Failed Assessments",
  avg_time_ans = "Average Time to Answer",
  created_at = "Created At",
  updated_at = "Updated At",
  registered_users = "Registered Users"
}
