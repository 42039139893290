import {
  ASSESSMENT_ERROR,
  ASSESSMENT_QUESTIONS,
  ASSESSMENT_RECORD,
  CANDIDATE_INTERVIEW_LISTING,
  CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING,
  GET_CANDIDATE_LOADING,
  GET_CURRENT_QUESTION,
  GET_JOBS_LOADING,
  GET_MATCHING_JOB_BY_ID,
  GET_MATCHING_JOBS,
  GET_TOP_MATCHING_JOB,
  JOB_ID_FOR_UPSKILL,
  QUESTION_INDEX,
  SEARCHED_MATCHING_JOBS,
  TENANT_INFO,
  TENANT_INFO_DETAILS,
  TENANT_INFO_LOADING,
  TOGGLE_JOB_FAV_STATUS_LOADING,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING,
  CANDIDATE_INTERVIEWS,
  CANDIDATE_JOBS,
  ASSESSMENT_QUESTIONNAIRES_ENGLISH,
  ASSESSMENT_QUESTIONNAIRES_ANNOTATION,
  APP_ENG_CURRENT_QUESTION_INDEX,
  APP_ENG_ASSESSMENT_CANCELLED,
  APP_ENG_ASSESSMENT_COMPLETED,
  ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE,
  APP_ASSESSMENT_SS,
  APP_ANNOTATION_CURRENT_QUESTION_INDEX,
  APP_ANNOTATION_ASSESSMENT_CANCELLED,
  APP_ANNOTATION_ASSESSMENT_COMPLETED,
  SCREEN_RECORDING_SUPPORTED,
  CAMERA_RECORDING_SUPPORTED,
  ENG_ASSESSMENT_COMPLETED,
  ENG_ASSESSMENT_CANCELLED,
  ANNOTATION_ASSESSMENT_CANCELLED,
  ANNOTATION_ASSESSMENT_COMPLETED,
  SCREEN_RECORDING_PERMISSION_GRANTED,
  CAMERA_RECORDING_PERMISSION_GRANTED
} from "@/store/modules/candidates/constants";
import {
  AppAnnotationAssessment,
  AppEnglishAssessment,
  AssessmentRecord,
  CandidateInterviews,
  CandidateInterviewsListing,
  CandidateJobs,
  CandidateState,
  MatchingJob,
  SearchedMatchingJobsPayload,
  TenantInfo
} from "@/store/modules/candidates/interfaces";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";
import { PrincipleAssessments } from "@/interfaces/data_objects/principle_assessments";
import { AssessmentQuestions } from "@/interfaces/responses/assessment_questions";

export default {
  [GET_CANDIDATE_LOADING]: (state: CandidateState): boolean => state.loading,
  [GET_JOBS_LOADING]: (state: CandidateState): boolean => state.jobs_loading,
  [UPSKILL_COURSES_LOADING]: (state: CandidateState): boolean =>
    state.upskill_courses_loading,
  [GET_MATCHING_JOBS]: (state: CandidateState): MatchingJob[] =>
    state.matching_jobs,
  [SEARCHED_MATCHING_JOBS]: (
    state: CandidateState
  ): SearchedMatchingJobsPayload => state.searched_matching_jobs,
  [GET_MATCHING_JOB_BY_ID]:
    (state: CandidateState) =>
    (job_id: number, search = false): MatchingJob | undefined => {
      if (search) {
        return state.candidate_jobs.top_matching_jobs.results.find(
          (_job) => _job.jid === job_id
        );
      }
      return state.candidate_jobs.matching_jobs.results.find(
        (_job) => _job.jid === job_id
      );
    },
  [GET_TOP_MATCHING_JOB]: (state: CandidateState): MatchingJob | null => {
    if (state.candidate_jobs.top_matching_jobs.results.length > 0)
      return state.candidate_jobs.top_matching_jobs.results[0];
    return null;
  },
  [JOB_ID_FOR_UPSKILL]: (state: CandidateState): number | null =>
    state.job_id_for_upskill,
  [UPSKILL_COURSES]: (
    state: CandidateState
  ): UpskillCourses.UpskillCoursesRoot | null => state.upskill_courses,
  [TOGGLE_JOB_FAV_STATUS_LOADING]: (state: CandidateState): boolean =>
    state.toggle_job_fav_status_loading,
  [CANDIDATE_INTERVIEW_LISTING]: (
    state: CandidateState
  ): CandidateInterviewsListing[] | null => state.interviews.listing,
  [CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING]: (state: CandidateState): boolean =>
    state.interviews.search_job_loading,
  [TENANT_INFO_LOADING]: (state: CandidateState): boolean =>
    state.tenant_info.loading,
  [TENANT_INFO_DETAILS]: (state: CandidateState): PrincipleAssessments | null =>
    state.tenant_info.details,
  [TENANT_INFO]: (state: CandidateState): TenantInfo => state.tenant_info,
  [ASSESSMENT_QUESTIONS]: (state: CandidateState): AssessmentQuestions.Root =>
    state.tenant_info.assessment_questions,
  [QUESTION_INDEX]: (state: CandidateState): number =>
    state.tenant_info.question_index,
  [ASSESSMENT_RECORD]: (state: CandidateState): AssessmentRecord[] =>
    state.tenant_info.assessment_record,
  [GET_CURRENT_QUESTION]: (state: CandidateState): AssessmentRecord =>
    state.tenant_info.assessment_record[state.tenant_info.question_index],
  [ASSESSMENT_ERROR]: (state: CandidateState): boolean =>
    state.tenant_info.error,
  [CANDIDATE_INTERVIEWS]: (state: CandidateState): CandidateInterviews | null =>
    state.interviews,
  // Candidate Jobs
  [CANDIDATE_JOBS]: (state: CandidateState): CandidateJobs =>
    state.candidate_jobs,
  // Get english assessment
  [ASSESSMENT_QUESTIONNAIRES_ENGLISH]: (
    state: CandidateState
  ): AppEnglishAssessment | null => state.app_assessments.english,
  // Get annotation assessment
  [ASSESSMENT_QUESTIONNAIRES_ANNOTATION]: (
    state: CandidateState
  ): AppAnnotationAssessment | null => state.app_assessments.annotation,
  [APP_ENG_CURRENT_QUESTION_INDEX]: (state: CandidateState): number => {
    if (!state.app_assessments || !state.app_assessments.english) {
      return 0;
    }
    return state.app_assessments.english.current_question_index;
  },
  [APP_ENG_ASSESSMENT_CANCELLED]: (state: CandidateState): boolean => {
    if (!state.app_assessments || !state.app_assessments.english) {
      return false;
    }
    return state.app_assessments.english.cancelled;
  },
  [APP_ENG_ASSESSMENT_COMPLETED]: (state: CandidateState): boolean => {
    if (!state.app_assessments || !state.app_assessments.english) {
      return false;
    }
    return state.app_assessments.english.completed;
  },
  [ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE]: (state: CandidateState): any => {
    return state.app_assessments.english;
  },
  [APP_ASSESSMENT_SS]: (state: CandidateState): File[] => {
    if (!state.app_assessments || !state.app_assessments.ss) {
      return [];
    }
    return state.app_assessments.ss;
  },
  [APP_ANNOTATION_CURRENT_QUESTION_INDEX]: (state: CandidateState): number => {
    if (!state.app_assessments || !state.app_assessments.annotation) {
      return 0;
    }
    return state.app_assessments.annotation.current_question_index;
  },
  [APP_ANNOTATION_ASSESSMENT_CANCELLED]: (state: CandidateState): boolean => {
    if (!state.app_assessments || !state.app_assessments.annotation) {
      return false;
    }
    return state.app_assessments.annotation.cancelled;
  },
  [APP_ANNOTATION_ASSESSMENT_COMPLETED]: (state: CandidateState): boolean => {
    if (!state.app_assessments || !state.app_assessments.annotation) {
      return false;
    }
    return state.app_assessments.annotation.completed;
  },
  [SCREEN_RECORDING_SUPPORTED]: (state: CandidateState): boolean =>
    state.screen_recording_supported,
  [CAMERA_RECORDING_SUPPORTED]: (state: CandidateState): boolean =>
    state.camera_recording_supported,
  [ENG_ASSESSMENT_COMPLETED]: (state: CandidateState): boolean =>
    state.eng_assessment_completed,
  [ENG_ASSESSMENT_CANCELLED]: (state: CandidateState): boolean =>
    state.eng_assessment_cancelled,
  [ANNOTATION_ASSESSMENT_CANCELLED]: (state: CandidateState): boolean =>
    state.annotation_assessment_cancelled,
  [ANNOTATION_ASSESSMENT_COMPLETED]: (state: CandidateState): boolean =>
    state.annotation_assessment_completed,
  [SCREEN_RECORDING_PERMISSION_GRANTED]: (state: CandidateState): boolean =>
    state.screen_recording_permission_granted,
  [CAMERA_RECORDING_PERMISSION_GRANTED]: (state: CandidateState): boolean =>
    state.camera_recording_permission_granted
};
