import axios, { AxiosResponse } from "axios";
import {
  ACTIVATE_PUBLISHED_JOB,
  FETCH_ASSESSMENT_LISTING,
  FETCH_FAVORITE_CANDIDATES,
  FETCH_INTERVIEW_HISTORY,
  FETCH_INTERVIEW_LISTING,
  FETCH_MEMBERS_JOBS_STATISTICS,
  FETCH_TOTAL_JOBS_COUNT,
  GET_JOBS_POSTED_BY_RECRUITER,
  IS_MATCHING_CANDIDATES_EXIST,
  POKE_CANDIDATE,
  POST_JOB,
  RESUME_INTERVIEW,
  SAVING_UPDATED_JOB_DESCRIPTION,
  SOFT_DEL_JOB,
  START_INTERVIEW,
  UPDATE_INTERVIEW_STATUS,
  UPDATE_JOB_DETAILS,
  GET_SEARCH_CANDIDATES,
  ZAPPYHIRE_JOB_POST,
  ZAPPYHIRE_CREATE_CANDIDATE,
  ZAPPYHIRE_SSO,
  FETCH_APP_ASSESSMENT_LISTING,
  DELETE_APP_ASSESSMENT_LISTING,
  INCEPTION_STATS,
  UPDATE_COMPANY_SETTING,
  FETCH_OVERALL_ASSESSMENT_LISTING,
  UPDATE_APPROVAL_CRITERIA,
  FETCH_CANCELLED_ASSESSMENT_CANDIDATES,
  FETCH_REDO_ASSESSMENT_CANDIDATES,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  GET_SURVEY_QUESTIONS,
  ADD_SURVEY_QUESTION,
  UPDATE_SURVEY_QUESTION,
  SUBMIT_SURVEY_QUESTION,
  DASHBOARD_COUNTS,
  DELETE_SURVEY_QUESTION
} from "@/store/urls/recruiter";
import {
  AppAssessmentListingPayload,
  AppOverAllAssessmentListing,
  DeleteAssessmentPayload,
  FetchAssessmentListingPayload,
  FetchAssessmentResult,
  GetJobsPostedPayload,
  InterviewListingPayload,
  // MatchingCandidatesByIdPayload,
  MembersJobsStatistics,
  PokeCandidatePayload,
  ResumeInterviewPayload,
  SaveUpdatedJobDescriptionPayload,
  SearchCandidatesPayload,
  StartInterviewPayload,
  UpdateCompanySettingPayload,
  UpdateFavoriteCandidatePayload,
  UpdateInterviewStatusPayload,
  UpdateJobPayload,
  UpdateApprovalCriteriaPayload,
  FetchCancelledAssessmentCandidatesPayload,
  DeleteCancelledAssessmentPayload,
  UpdateEmailTemplatePayload,
  AddSurveyQuestionPayload,
  SubmitSurveyQuestionsPayload
} from "@/store/modules/recruiter/interfaces";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { ZappyhirePostjobPayload } from "@/interfaces/recruiter/jobs/post_job/zappyhire_post_job";
import { ZappyhireCandidate } from "@/interfaces/data_objects/user";
import { REDO_ASSESSMENTS_REQUEST } from "../urls/common";

export const post_job = async (payload: FormData): Promise<AxiosResponse> => {
  return await axios.post(POST_JOB, payload);
};
// For update job
export const update_job = async (
  payload: UpdateJobPayload
): Promise<AxiosResponse> => {
  return await axios.post(UPDATE_JOB_DETAILS, payload);
};

export const get_jobs_posted_by_recruiter = async (
  payload: GetJobsPostedPayload
): Promise<AxiosResponse> => {
  return await axios.post(GET_JOBS_POSTED_BY_RECRUITER, payload);
};

/**
 * Function to softly delete job
 */
export const soft_delete_job = async (job_id: {
  job_id: number;
}): Promise<AxiosResponse> => {
  return await axios.post(SOFT_DEL_JOB, job_id);
};

/**
 * Function to activate published job
 */
export const activate_published_job = async (
  job_id: number
): Promise<AxiosResponse> => {
  return await axios.post(`${ACTIVATE_PUBLISHED_JOB}${job_id}`);
};

export const fetch_total_jobs_count = async (): Promise<AxiosResponse> => {
  return await axios.get(FETCH_TOTAL_JOBS_COUNT);
};

/**
 * Function to get member jobs stats
 */
export const fetch_members_jobs_stats = async (
  payload: MembersJobsStatistics
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_MEMBERS_JOBS_STATISTICS, payload);
};

/**
 * FUnction to get favorite candidate
 */
export const fetch_favorite_candidates = async (
  job_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_FAVORITE_CANDIDATES}${job_id}`);
};

/**
 * FUnction to update favorite candidate
 */
export const update_favorite_candidate = async (
  payload: UpdateFavoriteCandidatePayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_FAVORITE_CANDIDATES, payload);
};
export const update_approval_criteria = async (
  payload: UpdateApprovalCriteriaPayload
): Promise<AxiosResponse> => {
  return await axios.patch(UPDATE_APPROVAL_CRITERIA, payload);
};
export const get_email_templates = async (): Promise<AxiosResponse> => {
  return await axios.get(GET_EMAIL_TEMPLATES);
};
export const get_survey_questions = async (): Promise<AxiosResponse> => {
  return await axios.get(GET_SURVEY_QUESTIONS);
};
export const add_survey_question = async (
  payload: AddSurveyQuestionPayload
): Promise<AxiosResponse> => {
  return await axios.post(ADD_SURVEY_QUESTION, payload);
};

export const submit_survey_questions = async (
  payload: SubmitSurveyQuestionsPayload
): Promise<AxiosResponse> => {
  return await axios.post(SUBMIT_SURVEY_QUESTION, payload);
};
export const update_survey_question = async (
  payload: AddSurveyQuestionPayload,
  id: number
): Promise<AxiosResponse> => {
  console.log("api id====", id);

  return await axios.patch(UPDATE_SURVEY_QUESTION + `/${id}`, payload);
};
export const delete_survey_question = async (
  id: number
): Promise<AxiosResponse> => {
  return await axios.delete(DELETE_SURVEY_QUESTION + `/${id}`);
};
/**
 * Function to call backend API in order to poke candidate
 */
export const poke_candidate = async (
  payload: PokeCandidatePayload
): Promise<AxiosResponse> => {
  return await axios.post(POKE_CANDIDATE, payload);
};

// For saving updated job descriptions
export const save_updated_job_description = async (
  payload: SaveUpdatedJobDescriptionPayload
): Promise<AxiosResponse> => {
  return await axios.post(SAVING_UPDATED_JOB_DESCRIPTION, payload);
};

export const start_interview = async (
  payload: StartInterviewPayload
): Promise<AxiosResponse> => {
  return await axios.post(START_INTERVIEW, payload);
};

export const resume_interview = async (
  payload: ResumeInterviewPayload
): Promise<AxiosResponse> => {
  return await axios.post(RESUME_INTERVIEW, payload);
};

export const fetch_interview_listing = async (
  payload: InterviewListingPayload
): Promise<AxiosResponse> => {
  let query = "?";
  if (payload.page !== undefined) query += `page=${payload.page ?? 0}&`;
  if (payload.limit !== undefined) query += `limit=${payload.limit ?? 10}&`;
  if (payload.interviewed_by)
    query += `recruiter_id=${payload.interviewed_by}&`;
  if (payload.job_id) query += `job_id=${payload.job_id}&`;
  if (payload.candidate_id) query += `candidate_id=${payload.candidate_id}&`;
  if (payload.interview_id) query += `interview_id=${payload.interview_id}&`;
  return await axios.get(`${FETCH_INTERVIEW_LISTING}${query}`);
};

export const update_interview_status = async (
  payload: UpdateInterviewStatusPayload
): Promise<AxiosResponse> => {
  const data = new FormData();
  data.append("action", Interview.Status.FINISHED);
  if (payload.recording && payload.filename)
    data.append("recording", payload.recording, payload.filename);
  const id = payload.interview_id;
  return await axios.patch(`${UPDATE_INTERVIEW_STATUS}${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const fetch_interview_history = async (
  interview_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_INTERVIEW_HISTORY}${interview_id}`);
};

export const is_matching_candidates_exist = async (
  job_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${IS_MATCHING_CANDIDATES_EXIST}${job_id}`);
};

export const fetch_assessment_listing = async (
  payload: FetchAssessmentListingPayload
): Promise<AxiosResponse> => {
  let query = `${FETCH_ASSESSMENT_LISTING}?page=${payload.page}&limit=${payload.limit}`;

  if (payload.date_picker_field?.length === 2) {
    const dates = JSON.stringify(payload.date_picker_field);
    query += `&dates=${dates}`;
  }

  if (payload?.selected_searched_user?.length > 0) {
    query += `&users=${payload.selected_searched_user}`;
  }

  return await axios.get(query);
};

// Fetch cancelled assessments candidates
export const fetch_cancelled_assessment_candidates = async (
  payload: FetchCancelledAssessmentCandidatesPayload
): Promise<AxiosResponse> => {
  let query = `${FETCH_CANCELLED_ASSESSMENT_CANDIDATES}?assessment_version=${payload.assessment_version}&page=${payload.page}&limit=${payload.limit}`;
  if (payload?.user_ids?.length > 0) {
    query += `&user_ids=${JSON.stringify(payload.user_ids)}`;
  }

  return await axios.get(query);
};

// Fetch cancelled assessments candidates
export const fetch_redo_assessment_candidates = async (
  payload: FetchCancelledAssessmentCandidatesPayload
): Promise<AxiosResponse> => {
  let query = `${FETCH_REDO_ASSESSMENT_CANDIDATES}?assessment_version=${payload.assessment_version}&page=${payload.page}&limit=${payload.limit}`;
  if (payload?.user_ids?.length > 0) {
    query += `&user_ids=${JSON.stringify(payload.user_ids)}`;
  }

  return await axios.get(query);
};

export const get_search_candidates = async (
  payload: SearchCandidatesPayload
): Promise<AxiosResponse> => {
  return await axios.post(GET_SEARCH_CANDIDATES, payload);
};

export const zappyhire_post_job = async (
  payload: ZappyhirePostjobPayload
): Promise<AxiosResponse> => {
  return await axios.post(ZAPPYHIRE_JOB_POST, payload);
};

export const zappyhire_create_candidate = async (
  payload: ZappyhireCandidate,
  job_id: number,
  candidate_id: number
): Promise<AxiosResponse> => {
  return await axios.post(
    `${ZAPPYHIRE_CREATE_CANDIDATE}${job_id}/${candidate_id}`,
    payload
  );
};

export const zappyhire_sso = async (): Promise<AxiosResponse> => {
  return axios.get(ZAPPYHIRE_SSO);
};

export const fetch_app_assessment_listing = async (
  payload: AppAssessmentListingPayload
): Promise<AxiosResponse> => {
  let query = `${FETCH_APP_ASSESSMENT_LISTING}${
    payload.assessment_id
  }/listing/result/?page=${payload.page - 1}&limit=${payload.limit}`;

  if (payload.selected_dates.date_filter.length === 2) {
    query += `&from_date=${payload.selected_dates.date_filter[0]}&to_date=${payload.selected_dates.date_filter[1]}`;
  }
  if (payload.score_filter === "fail") query += `&fail=yes`;
  if (payload.score_filter === "pass") query += `&pass=yes`;
  if (payload.status_filter && payload.status_filter !== "all") {
    query += `&status=${payload.status_filter}`;
  }
  if (payload.selected_candidates && payload.selected_candidates.length > 0) {
    const userIds = payload.selected_candidates.map((user) => user.id);
    const users = userIds.join(",");
    query += `&user_ids=${users}`;
  }
  return axios.get(query);
};

export const get_assessment_result = async (
  payload: FetchAssessmentResult
): Promise<AxiosResponse> => {
  return axios.get(
    `${FETCH_APP_ASSESSMENT_LISTING}${payload.result_id}/result/?assessment_id=${payload.assessment_id}`
  );
};

export const delete_assessment = async (
  payload: DeleteAssessmentPayload
): Promise<AxiosResponse> => {
  return axios.post(
    `${DELETE_APP_ASSESSMENT_LISTING}${payload.assessment_id}/reset`,
    { user_id: payload.user_id }
  );
};
export const reset_all_cancelled_assessment = async (
  payload: DeleteCancelledAssessmentPayload
): Promise<AxiosResponse> => {
  return axios.post(`${DELETE_APP_ASSESSMENT_LISTING}reset_all`, {
    user_ids: payload.user_ids,
    assessment_version: payload.assessment_version,
    is_reset_all: payload.is_reset_all,
    is_redo: payload.is_redo ? payload.is_redo : false
  });
};
export const inception_stats = async (): Promise<AxiosResponse> => {
  console.log("Response stats: ");
  return axios.get(INCEPTION_STATS);
};

export const dashboard_counts = async (): Promise<AxiosResponse> => {
  console.log("Response1: ");
  return axios.get(DASHBOARD_COUNTS);
};
export const update_company_setting = async (
  payload: UpdateCompanySettingPayload
): Promise<AxiosResponse> => {
  return axios.patch(UPDATE_COMPANY_SETTING, payload);
};

export const fetch_app_overall_result = async (
  payload: AppOverAllAssessmentListing
): Promise<AxiosResponse> => {
  let query = `${FETCH_OVERALL_ASSESSMENT_LISTING}?assessment_versions=${payload.assessment_versions}&page=${payload.page}&limit=${payload.limit}`;

  if (payload.selected_dates.date_filter.length === 2) {
    query += `&from_date=${payload.selected_dates.date_filter[0]}&to_date=${payload.selected_dates.date_filter[1]}`;
  }
  if (payload.score_filter === "fail") query += `&fail=yes`;
  if (payload.score_filter === "pass") query += `&pass=yes`;
  if (payload?.download_report) {
    query += `&download_report=yes`;
  }
  query += `&average_score_min=${payload.average_score_filter[0]}&average_score_max=${payload.average_score_filter[1]}`;
  query += `&english_assessment_min=${payload.english_assessment_filter[0]}&english_assessment_max=${payload.english_assessment_filter[1]}`;
  query += `&annotation_assessment_min=${payload.annotation_assessment_filter[0]}&annotation_assessment_max=${payload.annotation_assessment_filter[1]}`;

  if (payload.selected_candidates && payload.selected_candidates.length > 0) {
    const userIds = payload.selected_candidates.map((user) => user.id);
    const users = userIds.join(",");
    query += `&user_ids=[${users}]`;
  }
  return axios.get(query);
};

// REDO Assessment API
export const redo_assessment_request = async (
  assessment_id: number
): Promise<AxiosResponse> => {
  console.log("Redo API called: ");
  return await axios.get(`${REDO_ASSESSMENTS_REQUEST}${assessment_id}/redo`);
};

// Email Templates
export const get_email_template = async (
  template_id: number
): Promise<AxiosResponse> => {
  return await axios.get(GET_EMAIL_TEMPLATE + "/" + template_id);
};
export const update_email_template = async (
  payload: UpdateEmailTemplatePayload
): Promise<AxiosResponse> => {
  return await axios.patch(
    UPDATE_EMAIL_TEMPLATE + "/" + payload.template_id,
    payload
  );
};
