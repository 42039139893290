import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const recruiter_inception_assessment_routes: RouteConfig = {
  path: "assessments",
  component: () =>
    import("@/views/recruiter/app_assessments/InceptionAssessmentWrapper.vue"),
  meta: {
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "english-assessment-report",
        params: { version: "v3" }
      },
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    {
      path: "english/:version",
      component: () =>
        import(
          "@/views/recruiter/app_assessments/InceptionAssessmentEnglishWrapper.vue"
        ),
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      },
      // Sub routes
      children: [
        {
          path: "",
          name: "english-assessment-report",
          component: () =>
            import(
              "@/views/recruiter/app_assessments/report/AppEngAssessmentReport.vue"
            ),
          meta: {
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          },
          children: []
        },
        {
          path: ":id/view/:user_id",
          name: "eng-assessment-report",
          component: () =>
            import(
              "@/views/recruiter/app_assessments/report/AppEngReportDetails.vue"
            ),
          meta: {
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          }
        },
        {
          path: "not-found",
          name: "eng-assessment-not-found",
          component: () => import("@/views/NotFound.vue"),
          meta: {
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          }
        }
      ]
    },
    // Inception Annotation Assessment Main Route (/assessments/annotation)
    {
      path: "annotation/:version",
      component: () =>
        import(
          "@/views/recruiter/app_assessments/InceptionAssessmentAnnotationWrapper.vue"
        ),
      meta: {
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      },
      // Sub routes
      children: [
        {
          path: "",
          name: "annotation-assessment-report",
          component: () =>
            import(
              "@/views/recruiter/app_assessments/report/AppAnnotationAssessmentReport.vue"
            ),
          meta: {
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          }
        },
        {
          path: ":id/view/:user_id",
          name: "annotation-assessment-report",
          component: () =>
            import(
              "@/views/recruiter/app_assessments/report/AppAnnotationReportDetails.vue"
            ),
          meta: {
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          }
        },
        {
          path: "not-found",
          name: "annotation-assessment-not-found",
          component: () => import("@/views/NotFound.vue"),
          meta: {
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          }
        }
      ]
    }
  ]
};
