import {
  candidate_base_url,
  recruiter_base_url,
  zappyhire_base_url
} from "@/store/urls/base_urls";

export const POST_JOB = recruiter_base_url + "post-job/";

// API endpoint to get jobs posted by recruiter
export const GET_JOBS_POSTED_BY_RECRUITER = recruiter_base_url + "jobs/";

export const GET_SEARCH_CANDIDATES = recruiter_base_url + "search-candidates/";

export const SOFT_DEL_JOB = recruiter_base_url + "delete-job";

// For change the status of job to active
export const ACTIVATE_PUBLISHED_JOB = recruiter_base_url + "publish-job/";

// Fetch recruiter dashboard numbers
export const FETCH_TOTAL_JOBS_COUNT = recruiter_base_url + "total-jobs/";

// For updating job
export const UPDATE_JOB_DETAILS = recruiter_base_url + "update-job/";

// For getting members/jobs statistics
export const FETCH_MEMBERS_JOBS_STATISTICS = recruiter_base_url + "stats/";

// For get favorite candidate against job & update favorite candidate against job
export const FETCH_FAVORITE_CANDIDATES = recruiter_base_url + "favorite/";

// For poke candidate
export const POKE_CANDIDATE = recruiter_base_url + "poke-candidates/";

// For saving updated job descriptions
export const SAVING_UPDATED_JOB_DESCRIPTION =
  recruiter_base_url + "update-job-description/";

export const START_INTERVIEW = recruiter_base_url + "start-interview/";
export const RESUME_INTERVIEW = recruiter_base_url + "resume-interview/";

export const FETCH_INTERVIEW_LISTING = recruiter_base_url + "interview/list/";

export const UPDATE_INTERVIEW_STATUS = recruiter_base_url + "interview/";

export const FETCH_INTERVIEW_HISTORY =
  recruiter_base_url + "interview/history/";

export const IS_MATCHING_CANDIDATES_EXIST =
  recruiter_base_url + "matching-candidates-exists/";

export const FETCH_ASSESSMENT_LISTING =
  candidate_base_url + "principles/assessments/list/";

export const ZAPPYHIRE_JOB_POST = zappyhire_base_url + "post-job/";

export const ZAPPYHIRE_CREATE_CANDIDATE =
  zappyhire_base_url + "sync-candidate/";

export const ZAPPYHIRE_SSO = zappyhire_base_url + "sso/";

export const FETCH_APP_ASSESSMENT_LISTING = recruiter_base_url + "assessments/";

export const DELETE_APP_ASSESSMENT_LISTING =
  recruiter_base_url + "assessments/";

export const INCEPTION_STATS = recruiter_base_url + "/inception/stats/";

export const UPDATE_COMPANY_SETTING = recruiter_base_url + "settings/company/";

export const FETCH_OVERALL_ASSESSMENT_LISTING =
  recruiter_base_url + "assessments/advance-filters/";

export const FETCH_CANCELLED_ASSESSMENT_CANDIDATES =
  recruiter_base_url + "cancelled-assessments";

export const FETCH_REDO_ASSESSMENT_CANDIDATES =
  recruiter_base_url + "redo-assessments";

export const UPDATE_APPROVAL_CRITERIA =
  recruiter_base_url + "approval-criteria";
export const GET_EMAIL_TEMPLATES = recruiter_base_url + "get_email_templates";
export const GET_EMAIL_TEMPLATE = recruiter_base_url + "get_email_template";
export const UPDATE_EMAIL_TEMPLATE =
  recruiter_base_url + "update_email_content";
export const GET_SURVEY_QUESTIONS = recruiter_base_url + "survey/get_questions";
export const GET_SURVEY_QUESTION = recruiter_base_url + "survey/get_question";
export const ADD_SURVEY_QUESTION = recruiter_base_url + "survey/add_question";
export const UPDATE_SURVEY_QUESTION =
  recruiter_base_url + "survey/update_question";
export const DELETE_SURVEY_QUESTION =
  recruiter_base_url + "survey/delete_question";
export const SUBMIT_SURVEY_QUESTION =
  recruiter_base_url + "survey/submit_survey_questions";

export const DASHBOARD_COUNTS = recruiter_base_url + "dashboard/stats/";
export const DASHBOARD_COUNTS1 = recruiter_base_url + "dashboard/stats/";
